<template>
  <b-overlay
    :show="show"
    variant="transparent"
    opacity="0.99"
    blur="5px"
    rounded="sm"
  >
    <template #overlay>
      <div class="text-center">
        <b-icon-controller
          font-scale="3"
          animation="cylon"
        />
        <p id="cancel-label">
          กรุณารอสักครู่...
        </p>
      </div>
    </template>
    <b-card no-body>
      <b-card-body>
        <div class="h4 mb-1">
          รายการ สรุปยอดเล่น
        </div>

        <div class="card-yellow-2 mb-2 text-center p-0">
          <div class="p-1">
            <div>
              สรุปยอดยอดเล่นทั้งหมด ของ พันธมิตร : {{ zean_detail.fullname }}
            </div>

            <p>
              {{ `(${dateStartShow} ถึง ${dateEndShow})` }}
            </p>
          </div>
          <hr class="m-0">

          <p class="p-1">
            {{ Commas(total_winloss) }} ฿
          </p>
        </div>

        <div class="row mb-2">
          <div class="col-md-4">
            <div class="card-yellow-2">
              <div class="text-white">
                Sport
              </div>
              <div>
                {{ Commas(sport_winloss) }} ฿
              </div>
            </div>
          </div>

          <div class="col-md-4">
            <div class="card-yellow-2">
              <div class="text-white">
                Slot
              </div>
              <div>
                {{ Commas(slot_winloss) }} ฿
              </div>
            </div>
          </div>

          <div class="col-md-4">
            <div class="card-yellow-2">
              <div class="text-white">
                Casino
              </div>
              <div>
                {{ Commas(casino_winloss) }} ฿
              </div>
            </div>
          </div>
        </div>

        <div class="row no-gutters">
          <div class="d-flex">
            <div class="d-flex align-start">
              <div class="btn-disable">
                วันที่ (จาก) :
              </div>
            </div>

            <flat-pickr
              v-model="dateStart"
              class="form-control"
              placeholder="เลือกเวลาเริ่มต้น"
              :config="{ maxDate: new Date().toISOString().split('T')[0] }"
            />
          </div>

          <div class="d-flex">
            <div class="d-flex align-start">
              <div class="btn-disable">
                วันที่ (ถึง) :
              </div>
            </div>

            <flat-pickr
              v-model="dateEnd"
              class="form-control"
              placeholder="เลือกเวลาเริ่มต้น"
              :config="{ maxDate: new Date().toISOString().split('T')[0] }"
            />
          </div>
          <button
            class="btn btn-primary ml-1"
            @click="GetList()"
          >
            ค้นหา
          </button>
        </div>
      </b-card-body>
    </b-card>
  </b-overlay>
</template>

<script>
import {
  BCard,
  BCardBody,
  BOverlay,
  BIconController,
} from 'bootstrap-vue'
import moment from 'moment-timezone'
import flatPickr from 'vue-flatpickr-component'

export default {
  components: {
    flatPickr,
    BCard,
    BCardBody,
    BOverlay,
    BIconController,
  },
  data() {
    return {
      itemModal: [],
      items: [],
      UserData: JSON.parse(localStorage.getItem('userData')),
      dateStart: moment().tz('Asia/Bangkok').format('YYYY-MM-DD'),
      dateEnd: moment().tz('Asia/Bangkok').format('YYYY-MM-DD'),
      invite_code: this.$route.params.id,
      zean_detail: { fullname: '' },
      ref_total: 0,
      search_val: '',
      show: false,
      total_winloss: 0,
      sport_winloss: 0,
      slot_winloss: 0,
      casino_winloss: 0,
      dateStartShow: '',
      dateEndShow: '',
    }
  },
  mounted() {
    // this.totalRows = this.items.length
    this.GetZeanDetail()
    this.GetList()
  },
  methods: {
    async GetZeanDetail() {
      const obj = {
        invite_code: this.invite_code,
      }
      try {
        const { data } = await this.$http.post('/zean/detail', obj)
        // console.log(data)
        this.zean_detail = data.detail
        this.ref_total = data.ref_total
      } catch (e) {
        console.log(e)
      }
    },
    async GetList() {
      const obj = {
        start_date: this.dateStart,
        end_date: this.dateEnd,
        invite_code: this.invite_code,
      }
      this.show = true
      try {
        const { data } = await this.$http.post('/zean/winloss', obj)
        console.log(data)
        // this.totalRows = data.total
        // this.items = data.data
        this.total_winloss = data.total_winloss
        this.sport_winloss = data.sport_winloss
        this.slot_winloss = data.slot_winloss
        this.casino_winloss = data.casino_winloss
        this.dateStartShow = this.dateStart
        this.dateEndShow = this.dateEnd
        this.show = false
      } catch (e) {
        console.log(e)
        this.show = false
      }
    },
    Commas(x) {
      if (!x) {
        return 0
      } if (x % 1 !== 0) {
        return Number(x).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      }
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
    Success(mes) {
      this.$swal({
        icon: 'success',
        title: '<h3 style="color: #141414">ทำรายการสำเร็จ</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })
    },
    SwalError(mes) {
      this.$swal({
        icon: 'error',
        title: '<h3 style="color: #141414">เกิดข้อผิดพลาด!</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      })
    },
  },
}
</script>

<style scoped>
.btn {
  box-shadow: 0 4px 8px -4px rgba(94, 86, 105, 0.42) !important;
}
.row {
  flex-wrap: wrap;
  flex: 1 1 auto;
}
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
.form-item-section {
  background-color: $product-details-bg;
}
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
